import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { bodyCopy, buttonOne, colors } from "../../Utilities"
import TroutIcon from "../../elements/TroutIcon"

const AlertModal = ({ alertactive, message, error, uiDispatch }) => {
  const [fishClass, setFishClass] = useState("")
  const [aninationClass, setAnimationClass] = useState("")

  useEffect(() => {
    setFishClass(error === true ? "" : " alertFish")
  }, [alertactive])

  const handleDismiss = () => {
    setAnimationClass(error === true ? "" : " alertSuccess")

    if (error === true) {
      uiDispatch({ type: "ALERT_CLEAR" })
    } else {
      setTimeout(() => {
        setAnimationClass(error === true ? "" : "")
        setFishClass(error === true ? "" : "")
        uiDispatch({ type: "ALERT_CLEAR" })
      }, 2000)
    }
  }

  return (
    <AlertModalStyled active={alertactive} status={`success`} error={error}>
      <div className={`alertOuter${fishClass}${aninationClass}`}>
        {fishClass !== "" && (
          <div className="fish">
            <TroutIcon />
          </div>
        )}

        <div className={`alertInner${aninationClass}`}>
          <p>{message}</p>
          <div className="btnDismiss">
            <button
              onClick={() => {
                handleDismiss()
              }}
            >
              Dismiss
            </button>
          </div>
        </div>
      </div>
    </AlertModalStyled>
  )
}

const AlertModalStyled = styled.div`
  display: ${props => (props.active ? "block" : "none")};
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  z-index: ${props => (props.active ? 99999999999 : -1)};
  opacity: ${props => (props.active ? 1 : 0)};
  visibility: ${props => (props.active ? "visible" : "hidden")};

  .alertInner {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: scale(1) translate(-50%, -50%);
    padding: 7.5rem 5rem;
    border-radius: 0.75rem;
    background-color: ${props =>
      props.error ? colors.colorTertiary : colors.colorPrimary};
    opacity: 1;

    @media (min-width: 768px) {
      width: 100%;
      max-width: 55rem;
    }

    @media (min-width: 1025px) {
      width: 100%;
      max-width: 65rem;
    }
    &__logo {
      max-width: 7.5rem;
      margin: 0 auto 3rem;
    }

    p {
      ${bodyCopy};
      width: 100%;
      color: ${colors.white};
      margin: 0;
      text-align: center;
    }

    &.alertSuccess {
      transition: opacity 1s ease-in;
      opacity: 0;
    }
  }

  .alertFish {
    .fish {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: scale(1) translate(-50%, -50%);
      width: calc(15rem * 1.5);
      height: calc(10rem * 1.5);
      z-index: -1;
    }

    &.alertSuccess {
      .fish {
        transition: top 1s ease-in, left 1s ease-in, opacity 5s ease-in,
          transform 1s ease-in;
        top: -0%;
        left: 82%;
        transform: scale(0) translate(-50%, -50%);
        opacity: 0;
      }
    }
  }

  .btnDismiss {
    display: block;
    position: absolute;
    top: 2.5rem;
    right: 2rem;

    button {
      ${buttonOne};
    }
  }
`

export default AlertModal
