import React from "react"
import Img from "gatsby-image"
import { StaticQuery, graphql } from "gatsby"

const TroutIcon = () => {
  return (
    <StaticQuery
      query={graphql`
        {
          troutIcon: file(relativePath: { eq: "troutloader.png" }) {
            childImageSharp {
              fluid(maxWidth: 200) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      `}
      render={data => {
        return (
          <>
            <Img fluid={data.troutIcon.childImageSharp.fluid} alt="Loading" />
          </>
        )
      }}
    />
  )
}

export default TroutIcon
