export const updateShippingCosts = (
  country,
  province,
  serverRates,
  cartSubTotalInCents
) => {
  let shippingRate = 1700
  const freeShippingThroshold = serverRates.free_shipping_threshold * 100

  if (cartSubTotalInCents >= freeShippingThroshold) return (shippingRate = 0)

  if (serverRates === undefined) {
    return shippingRate
  }
  if (country === "usa") {
    switch (province) {
      default:
        return (shippingRate = serverRates.usa_shipping_cost * 100)
    }
  } else {
    switch (province) {
      case "alberta":
        shippingRate = serverRates.alberta_shipping_rate * 100
        break
      case "british_columbia":
        shippingRate = serverRates.british_columbia_shipping_rate * 100
        break
      case "manitoba":
        shippingRate = serverRates.manitoba_shipping_rate * 100
        break
      case "new_brunswick":
        shippingRate = serverRates.new_brunswick_shipping_rate * 100
        break
      case "newfoundland_and_labrabor":
        shippingRate = serverRates.newfoundland_and_labrabor_shipping_rate * 100
        break
      case "northwest_territories":
        shippingRate = serverRates.northwest_territories_shipping_rate * 100
        break
      case "nova_scotia":
        shippingRate = serverRates.nova_scotia_shipping_rate * 100
        break
      case "nunavut":
        shippingRate = serverRates.nunavut_shipping_rate * 100
        break
      case "ontario":
        shippingRate = serverRates.ontario_shipping_rate * 100
        break
      case "prince_edward_island":
        shippingRate = serverRates.prince_edward_island_shipping_rate * 100
        break
      case "quebec":
        shippingRate = serverRates.quebec_shipping_rate * 100
        break
      case "saskatchewan":
        shippingRate = serverRates.saskatchewan_shipping_rate * 100
        break
      case "yukon_territory":
        shippingRate = serverRates.yukon_territory_shipping_rate * 100
        break
      default:
        shippingRate = 1700
        break
    }
  }
  return shippingRate
}
