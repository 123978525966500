import { colors } from "./index"
import { fonts, fontSizer } from "./index"

export const headlineOne = `
    ${fontSizer(3, 7, 76.8, 150, 3)}
    color: ${colors.black}
    font-family: ${fonts.fontSecondary};
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    letter-spacing: 1.75px;
  `

export const headlineTwo = `
    ${fontSizer(2.4, 4, 76.8, 160, 3)}
    color: ${colors.black};
    font-style: normal;
    font-weight: normal;
    line-height: 1.25;
    letter-spacing: 1px;
  `

export const headlineThree = `
    ${fontSizer(2, 2.4, 76.8, 160, 2)}
    color: ${colors.black}
    font-family: ${fonts.fontPrimary};
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 2.5;
    letter-spacing: 0.6px;
  `

export const headlineFive = `
    ${fontSizer(30, 50, 76.8, 160, 15)};
    margin: 0;
    color: ${colors.colorAccent};
    font-family: ${fonts.fontSecondary};
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.14;
    letter-spacing: normal;
    text-align: center;
    opacity: 0.15;
`
