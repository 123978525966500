import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"

import { colors, fonts, fontSizer } from "../../../Utilities"

const InformationLinksStyled = styled.div`
  display: none;

  @media (min-width: 768px) {
    display: flex;
    justify-content: flex-end;
    background: ${colors.black};
  }

  .info-link-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 160rem;
    margin: auto;

    @media (min-width: 1025px) {
      width: 80%;
      justify-content: flex-end;
    }
  }

  a {
    ${fontSizer(1, 1.2, 76.8, 160, 2)};
    margin: 0 1rem;
    padding: 1rem 2rem;
    color: ${colors.colorAccent};
    font-family: ${fonts.fontPrimary};
    text-transform: uppercase;

    @media (min-width: 1025px) {
      margin: 0 1rem;
      padding: 1rem 2rem;
    }

    &:hover {
      color: ${colors.colorShadowLight};
    }
  }
`

const getData = graphql`
  query informationLinks {
    wordpressAcfOptions {
      options {
        dow_sportfishing_regulations
        dow_fishing_license
        dow_river_conditions
      }
    }
  }
`

const InformationLinks = props => {
  const data = useStaticQuery(getData)
  const regUrl = data.wordpressAcfOptions.options.dow_sportfishing_regulations
  const liceUrl = data.wordpressAcfOptions.options.dow_fishing_license
  const condUrl = data.wordpressAcfOptions.options.dow_river_conditions

  return (
    <InformationLinksStyled>
      <div className="info-link-wrapper">
        <a target="_blank" rel="noopener noreferrer" href={regUrl}>
          Sportfishing Regulations
        </a>
        <a target="_blank" rel="noopener noreferrer" href={liceUrl}>
          Fishing License
        </a>
        <a target="_blank" rel="noopener noreferrer" href={condUrl}>
          River Conditions
        </a>
      </div>
    </InformationLinksStyled>
  )
}

export default InformationLinks
