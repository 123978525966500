import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import styled, { ThemeProvider } from "styled-components"

import theme from "./styles/Theme"
import GlobalStyle from "./styles/Golbal"
import Header from "./header"
import Footer from "./Footer"
import UiModals from "../components/UiElements/UiModals"

const Layout = ({ children, location }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  useEffect(() => {
    if (typeof window !== `undefined`) {
      window.scroll({
        top: 0,
        left: 0,
      })
    }
  }, [])

  return (
    <>
      <ThemeProvider theme={theme}>
        <React.Fragment>
          <GlobalStyle />
          <UiModals />
          <Header
            location={location}
            siteTitle={data.site.siteMetadata.title}
          />
          <div>
            <StyledMain>{children}</StyledMain>
            <Footer />
          </div>
        </React.Fragment>
      </ThemeProvider>
    </>
  )
}

const StyledMain = styled.main`
  overflow: hidden;
`

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
