import { colors } from "./index"
import { fonts, fontSizer } from "./index"

export const buttonOne = `
  ${fontSizer(1.4, 1.6, 76.8, 110, 2)};
  display: inline-block;
  min-width: 18rem;
  padding: 0.25rem 3rem;
  color: ${colors.colorAccent};
  background: #fff;
  transition: all 0.3s ease;
  border: solid 0.1rem ${colors.colorAccent};
  box-shadow: -1rem -1rem 0 0 ${colors.colorAccent};
  font-family: ${fonts.fontPrimary};
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 1.5;

  &:hover {
    color: ${colors.colorTertiary};
    border: solid 0.1rem ${colors.colorTertiary};
    box-shadow: -1rem -1rem 0 0 ${colors.colorTertiary};
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    color: ${colors.black};
    border: solid 0.1rem ${colors.black};
    box-shadow: -1rem -1rem 0 0 ${colors.black};
    opacity: 0.5;
    cursor: not-allowed;
  }
`
