/**
 * Formats a integer price into a price with decimals with a preceding $ sign
 * @param {string} - price
 */
export const formatPrice = price => {
  const realPrice = parseInt(price) / 100
  return realPrice.toLocaleString("en-CA", {
    style: "currency",
    currency: "CAD",
  })
}
