import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"

import MainNavItem from "./MainNavItem"
import MainNavBook from "./MainNavBook"
import SocialFollow from "../../SocialFollow"
import CartButton from "./CartButton"

const MainNavStyled = styled.nav`
  position: relative;
  z-index: 999999;
  @media (min-width: 768px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
    width: 100%;
    margin: 2rem auto;
    padding: 0 2rem;
    z-index: 10;
  }

  @media (min-width: 1025px) {
    max-width: 160rem;
  }

  .social-follow-wrapper {
    display: none;

    @media (min-width: 768px) {
      display: block;
      width: 100%;
      align-self: center;
      margin: 2rem auto;
    }
    @media (min-width: 1025px) {
      width: 10%;
      margin: 0;
    }
  }
`

const MainNavUl = styled.ul`
  display: none;

  @media (min-width: 768px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 80%;
  }

  @media (min-width: 1025px) {
    justify-content: center;
    width: 80%;
  }
`

const MainLogo = styled.div`
  display: none;

  @media (min-width: 768px) {
    display: block;
    align-self: center;
    width: 100%;
    margin: 0 auto;
  }

  @media (min-width: 1025px) {
    width: 10%;
  }

  h1 {
    position: absolute;
    left: -999%;
  }

  a {
    display: block;
    max-width: 20rem;
    margin: 1rem auto;

    @media (min-width: 1025px) {
      margin: 0;
    }
  }
`

const MainNav = ({ siteTitle, location }) => {
  return (
    <StaticQuery
      query={graphql`
        {
          wordpressWpApiMenusMenusItems(name: { eq: "Main Menu" }) {
            items {
              wordpress_id
              title
              object_slug
              url
              wordpress_children {
                wordpress_id
                title
                object_slug
              }
            }
          }

          wordpressAcfOptions {
            options {
              dow_main_logo {
                alt_text
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 500) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        const menuItems = data.wordpressWpApiMenusMenusItems.items
        const mainLogo = data.wordpressAcfOptions.options.dow_main_logo

        return (
          <MainNavStyled>
            <MainLogo>
              <h1>{siteTitle}</h1>
              <Link to="/">
                <Img
                  fluid={mainLogo.localFile.childImageSharp.fluid}
                  alt={mainLogo.alt_text}
                />
              </Link>
            </MainLogo>
            <MainNavUl>
              {menuItems.map((item, index) => {
                return <MainNavItem key={index} {...item} location={location} />
              })}
              <MainNavItem
                object_slug="shop"
                title="Shop"
                location={""}
                wordpress_children={[
                  { title: "Cart", object_slug: "cart" },
                  { title: "Checkout", object_slug: "checkout" },
                ]}
                location={location}
              />
            </MainNavUl>
            {/* <MainNavBook /> */}
            <div className="social-follow-wrapper">
              <SocialFollow location={location} header={true} />
            </div>
          </MainNavStyled>
        )
      }}
    />
  )
}

export default MainNav
