import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaLinkedinIn,
} from "react-icons/fa"

import { colors } from "../Utilities"
import CartButton from "./Navigation/MainNav/CartButton"

const SocialFollowStyled = styled.div`
  align-self: center;
  padding: 0;

  .wrapper {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }

  .social-icon {
    align-self: center;
    position: relative;
    width: 3.5rem;
    height: 3.5rem;

    &:first-of-type {
      margin-left: 0;
    }

    a {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 0.5rem;
      color: ${colors.black};

      svg {
        width: 100%;
        height: 100%;
      }
    }

    &__facebook {
      a {
        &:hover {
          color: #3b5998;
        }
      }
    }

    &__twitter {
      a {
        &:hover {
          color: #1da1f2;
        }
      }
    }

    &__instagram {
      a {
        &:hover {
          color: #405de6;
        }
      }
    }

    &__linkedin {
      a {
        &:hover {
          color: #0077b5;
        }
      }
    }
  }
`

const getData = graphql`
  query socialFollow {
    wordpressAcfOptions {
      options {
        dow_social_media {
          social_url
          social_type
        }
      }
    }
  }
`

const SocialFollow = (props) => {
  const data = useStaticQuery(getData)
  const socialMediaData = data.wordpressAcfOptions.options.dow_social_media
  const { location } = props
  const isNotCheckoutPage =
    location === "/shop/checkout" ||
    location === "/shop/checkout/" ||
    location === "/checkout/success" ||
    location === "/checkout/success/"
      ? false
      : true

  return (
    <SocialFollowStyled>
      <div className="wrapper">
        {props.header && isNotCheckoutPage && <CartButton />}
        {socialMediaData.map((icon, index) => {
          const iconDisplay =
            icon.social_type === "facebook" ? (
              <FaFacebookF />
            ) : icon.social_type === "twitter" ? (
              <FaTwitter />
            ) : icon.social_type === "instagram" ? (
              <FaInstagram />
            ) : icon.social_type === "linkedin" ? (
              <FaLinkedinIn />
            ) : (
              false
            )
          return (
            <div
              className={`social-icon social-icon__${icon.social_type}`}
              key={index}
            >
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={icon.social_url}
              >
                {iconDisplay}
              </a>
            </div>
          )
        })}
      </div>
    </SocialFollowStyled>
  )
}

export default SocialFollow
