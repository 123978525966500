import React from "react"
import styled from "styled-components"

import { colors, fontSizer } from "../../../Utilities"

const EmployeeStyled = styled.div`
  display: none;

  @media (min-width: 768px) {
    display: block;
    width: calc(20%);
    text-align: center;
  }

  @media (min-width: 1025px) {
    width: calc(15%);
  }

  a {
    ${fontSizer(1.4, 1.6, 76.8, 160, 2)};
    display: block;
    width: 100%;
    padding: 2rem 0;
    background: ${colors.colorAccent};
    color: ${colors.white};

    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: normal;
    line-height: 1.19;
  }
`

const MainNavStart = () => {
  return (
    <EmployeeStyled>
      <a
        rel="noopener noreferrer"
        target="_blank"
        href="https://square.site/book/JDNR9AWBFQ62H/drift-out-west-fly-fishing"
      >
        Book With Quinn
      </a>
    </EmployeeStyled>
  )
}

export default MainNavStart
