import React from "react"
import styled from "styled-components"

import MainNav from "./Navigation/MainNav/MainNav"
import MobileMenu from "./Navigation/MobileMenu/MobileMenu"
import InformationLinks from "./Navigation/InformationLinks/InformationLinks"

const Header = ({ siteTitle, location }) => (
  <HeaderStyled>
    <InformationLinks />
    <MainNav siteTitle={siteTitle} location={location} />
    <MobileMenu location={location} />
  </HeaderStyled>
)

const HeaderStyled = styled.header`
  position: relative;
  z-index: 999999;

  @media print {
    display: none;
    margin: 0;
    padding: 0;
  }
`

export default Header
