import * as primary from "../../../fonts/primary"

const PrimaryFonts = `

@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 300;
  src: local('Work Sans Light'), local('WorkSans-Light'),
       url('${primary.WOFF2_3}') format('woff2'),
       url('${primary.WOFF_3}') format('woff');
}

@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Work Sans'), local('WorkSans-Regular'),
       url('${primary.WOFF2_4}') format('woff2'),
       url('${primary.WOFF_4}') format('woff');
}

@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  src: local('Work Sans Medium'), local('WorkSans-Medium'),
       url('${primary.WOFF2_5}') format('woff2'),
       url('${primary.WOFF_5}') format('woff');
}

@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 700;
  src: local('Work Sans Bold'), local('WorkSans-Bold'),
       url('${primary.WOFF2_7}') format('woff2'),
       url('${primary.WOFF_7}') format('woff');
}

`

export default PrimaryFonts
