import { Link } from "gatsby"
import Img from "gatsby-image"
import React, { useContext, useState, useEffect } from "react"
import styled from "styled-components"
import axios from "axios"
import { CartContext } from "../../../context/CartContext"
import { RatesContext } from "../../../context/RatesContext"
import { UiContext } from "../../../context/UiContext"
import CartIcon from "../../../elements/CartIcon"
import { bodyCopy, buttonOne, colors, fontSizer } from "../../../Utilities"
import { updateShippingCosts } from "../../../Utilities/cart/rates"
import {
  cartSubtotal,
  cartTotalTax,
  cartTotal as getCartTotal,
} from "../../../Utilities/cart/cart"
import { formatPrice } from "../../../Utilities/cart/format"

const CartButton = () => {
  const [isCartActive, setIsCartActive] = useState(false)
  const [rateState, rateDispatch] = useContext(RatesContext)
  const { cart, cartTotal, changeCartQty } = useContext(CartContext)
  const { state: uiState, dispatch: uiDispatch } = useContext(UiContext)

  const shippingCostToUse =
    cart && cart.length > 0 ? rateState.activeShipping : 0

  const handleAddToCartTotals = async (product) => {
    uiDispatch({ type: "SERVER_LOAD" })
    const hasVariant = product.variants_picked.length === 0 ? false : true
    let confirmedMaxTotal
    let confirmedProductName
    if (hasVariant) {
      const res = await axios.get(
        `${process.env.GATSBY_API_URL}/variations/${product.var_selected_stock_id}`
      )
      confirmedMaxTotal = res.data.stock
      confirmedProductName = res.data.name
    } else {
      const res = await axios.get(
        `${process.env.GATSBY_API_URL}/products/${product.strapiId}`
      )
      confirmedMaxTotal = res.data.stock
      confirmedProductName = res.data.name
    }
    if (product.qty >= confirmedMaxTotal) {
      uiDispatch({ type: "SERVER_LOAD_FINISHED" })
      uiDispatch({
        type: "ALERT_ERROR",
        payload: `The total stock we have is ${confirmedMaxTotal}. There is currenly no more than ${confirmedMaxTotal} ${confirmedProductName} in stock.`,
      })
    } else {
      uiDispatch({ type: "SERVER_LOAD_FINISHED" })
      changeCartQty(product, "add")
    }
  }

  // useEffect(() => {
  //   updateCartShippingCost()
  // }, [])

  useEffect(() => {
    if (cartTotal > 0) {
      updateCartShippingCost()
    }
  }, [cartTotal])

  const updateCartShippingCost = async () => {
    // Update shipping cost. //
    uiDispatch({ type: "SERVER_LOAD" })
    const reponse = await axios.get(`${process.env.GATSBY_API_URL}/shop-rates`)
    const freeShippingThreshold = reponse.data.free_shipping_threshold * 100
    const cartSubTotalInCents = cartSubtotal(cart)
    const shippingRate = updateShippingCosts(
      rateState.activeLocation,
      rateState.activeProvince,
      reponse.data,
      cartSubTotalInCents
    )

    const SHIPPING_REQUIRED = cart.some((item) => item.should_charge_shipping)

    rateDispatch({
      type: "RATE_CHANGE",
      payload: {
        activeShipping: !SHIPPING_REQUIRED ? 0 : shippingRate,
        freeShipping: !SHIPPING_REQUIRED
          ? true
          : cartSubTotalInCents >= freeShippingThreshold,
      },
    })
    uiDispatch({ type: "SERVER_LOAD_FINISHED" })
  }

  const mainSubTotal = cartSubtotal(cart)

  return (
    <CartButtonStyled
      iscartactive={isCartActive}
      onMouseEnter={() => setIsCartActive(true)}
      onMouseLeave={() => setIsCartActive(false)}
    >
      <Link to={`/shop/cart`}>
        <CartIcon />
        <span className="totalNumber">{cartTotal}</span>
      </Link>
      <div className="cartItems">
        <span className="icon">
          <CartIcon />
        </span>
        <p>Your Cart</p>
        <ul>
          {cart.length > 0 ? (
            cart.map((product) => {
              return (
                <li key={product.cart_id}>
                  <span className="productImage">
                    {product.selected_varient_details &&
                    product.selected_varient_details.image &&
                    product.selected_varient_details.image.childImageSharp
                      .fluid ? (
                      <>
                        <Img
                          fluid={
                            product.selected_varient_details.image
                              .childImageSharp.fluid
                          }
                        />
                      </>
                    ) : (
                      <>
                        <Img
                          fluid={product.featured_image.childImageSharp.fluid}
                        />
                      </>
                    )}
                  </span>
                  <span className="productName">
                    {product.name}
                    {product.variants_picked.length > 0 ? (
                      <span className="productName__variants">
                        {product.variants_picked.map((variant, index) => {
                          return (
                            <span
                              className="productName__variants--item"
                              key={index}
                            >
                              {Object.keys(variant)[0]} :{" "}
                              {Object.values(variant)[0]}
                            </span>
                          )
                        })}
                      </span>
                    ) : null}
                  </span>
                  <span className="productPrice">
                    {formatPrice(product.price_in_cents * product.qty)}
                  </span>
                  <span className="productQty">
                    qty: {product.qty}
                    <span className="quantity-nav">
                      <button
                        className="quantity-nav__add"
                        onClick={() => handleAddToCartTotals(product)}
                        type="button"
                      >
                        &#43;
                      </button>
                      <button
                        className="quantity-nav__subtract"
                        onClick={() => changeCartQty(product, "subtract")}
                        type="button"
                      >
                        &#8722;
                      </button>
                      <button
                        className="quantity-nav__delete"
                        onClick={() => changeCartQty(product, "delete")}
                        type="button"
                      >
                        &times;
                      </button>
                    </span>
                  </span>
                </li>
              )
            })
          ) : (
            <p>Nothing in your Cart</p>
          )}
        </ul>
        <div className="cartTotals">
          <p>Subtotal: {formatPrice(mainSubTotal)}</p>
          {rateState.giftCard > 0 ? (
            <p>Gift Card: {formatPrice(rateState.giftCard)}</p>
          ) : null}
          <p>
            Tax:{" "}
            {formatPrice(
              cartTotalTax(
                mainSubTotal,
                rateState.activeTax,
                rateState.giftCard
              )
            )}
          </p>
          <p>
            {!rateState.freeShipping ? `Estimated Shipping:` : `Free Shipping`}{" "}
            {formatPrice(rateState.activeShipping)}
          </p>

          <p>
            Cart Total:{" "}
            {formatPrice(
              getCartTotal(
                cart,
                rateState.activeShipping,
                rateState.activeTax,
                rateState.giftCard
              )
            )}
          </p>
        </div>
        <div className="checkoutBtn">
          <Link to={`/shop/cart`}>Cart</Link>
          <Link to={`/shop/checkout`}>Checkout</Link>
        </div>
      </div>
    </CartButtonStyled>
  )
}

const CartButtonStyled = styled.div`
  position: relative;
  align-self: center;
  width: 3.5rem;
  height: 3.5rem;
  padding: 0;
  z-index: 999999999;

  @media (min-width: 1025px) {
    width: 3rem;
    margin: 0 auto;
  }

  a {
    display: block;
    position: relative;
    width: 3rem;
    height: 3rem;
  }

  .totalNumber {
    display: block;
    position: absolute;
    z-index: 10;
    left: -1rem;
    bottom: -1rem;
    width: 2.5rem;
    height: 2.5rem;
    background-color: ${colors.white};
    border-radius: 50%;
    border: 0.1rem solid ${colors.colorPrimary};
    color: ${colors.colorTertiary};
    font-size: 1.6rem;
    text-align: center;
    line-height: 1.5;
  }

  .cartItems {
    position: absolute;
    top: calc(100% + 1.5rem);
    right: calc(-45rem / 2);
    width: 45rem;
    padding: 2rem;
    background-color: ${colors.white};
    border: solid 1px #707070;
    transition: all 0.3s ease-out;
    box-shadow: 4px 2px 2px 0 rgba(0, 0, 0, 0.25);
    opacity: ${(props) => (props.iscartactive ? 1 : 0)};
    visibility: ${(props) => (props.iscartactive ? "visible" : "hidden")};

    @media (min-width: 1025px) {
      right: -5rem;
    }

    p {
      ${bodyCopy};
      display: inline-block;
      color: ${colors.colorAccent};
      text-transform: uppercase;
      line-height: 1.25;
    }

    ul {
      width: 100%;

      li {
        ${bodyCopy};
        font-size: 1.6rem;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: flex-start;
        width: 100%;
        margin-bottom: 0.75rem;
        padding-bottom: 0.75rem;
        border-bottom: 0.1rem solid ${colors.black};
        color: ${colors.black};
        line-height: 1.5;

        @media (min-width: 768px) {
          font-size: 1.4rem;
        }

        @media (min-width: 1025px) {
          ${fontSizer(1, 1.2, 76.8, 150, 1.6)};
        }

        .productImage {
          display: inline-block;
          width: 12.5%;
        }

        .productName {
          display: inline-block;
          width: calc(50% - 2rem);
          margin: 0 1rem;

          &__variants {
            display: block;

            &--item {
              display: block;
            }
          }
        }

        .productPrice {
          display: inline-block;
          width: calc(17.5%);
          text-align: center;
        }

        .productQty {
          display: inline-block;
          width: calc(20%);
          text-align: center;

          .quantity-nav {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;

            @media (min-width: 1025px) {
              flex-wrap: nowrap;
            }

            button {
              display: block;
              width: 100%;
              height: 2.25rem;
              margin-bottom: 0.5rem;
              transition: all 0.3s ease-out;
              border: 0.1rem solid #eee;
              background-color: ${colors.white};
              color: ${colors.sliver};
              cursor: pointer;

              &:disabled {
                opacity: 0.5;
                cursor: not-allowed;
              }

              @media (min-width: 1025px) {
                display: inline-block;
                width: 2.25rem;
                height: 2.25rem;
                margin-bottom: 0;
              }
            }

            button.quantity-nav__add {
              color: ${colors.colorPrimary};

              &:hover {
                background-color: ${colors.colorPrimary};
                color: ${colors.white};
              }
            }

            button.quantity-nav__subtract {
              color: ${colors.colorPrimary};

              &:hover {
                background-color: ${colors.colorPrimary};
                color: ${colors.white};
              }
            }

            button.quantity-nav__delete {
              color: ${colors.colorTertiary};

              &:hover {
                background-color: ${colors.colorTertiary};
                color: ${colors.white};
              }
            }
          }
        }
      }
    }

    .icon {
      display: inline-block;
      width: 2rem;
      margin-right: 1rem;
    }
  }

  .cartTotals {
    width: 100%;
    p {
      ${bodyCopy};
      ${fontSizer(1, 1.2, 76.8, 150, 1.6)};
      display: block;
      margin-bottom: 0.5rem;

      @media (min-width: 768px) {
        font-size: 1.4rem;
      }

      @media (min-width: 1025px) {
        ${fontSizer(1, 1.2, 76.8, 150, 1.6)};
      }
    }
  }

  .checkoutBtn {
    margin-top: 5rem;
    margin-bottom: 2.5rem;
    text-align: center;

    a {
      ${buttonOne};
      margin: 2rem;
    }
  }
`

export default CartButton
