import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery, Link } from "gatsby"
import BgImg from "gatsby-background-image"

import { colors, fontSizer, medWrapper, fonts, buttonOne } from "../Utilities"
import SocialFollow from "./SocialFollow"

const getData = graphql`
  query footerOptions {
    wordpressAcfOptions {
      options {
        dow_footer_background_image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        dow_column_one_title_top
        dow_column_one_title_bot
        dow_column_one_btn_text
        dow_column_one_btn_url
        dow_column_two_title_top
        dow_column_two_title_bot
        dow_column_two_btn_text
        dow_column_two_btn_url
        dow_switchback_credit
      }
    }
  }
`

const FooterStyled = styled.footer`
  @media print {
    display: none;
    margin: 0;
    padding: 0;
  }
  .footer-hero-image {
    display: flex;
    position: relative;
    width: 100%;
    min-height: 30rem;
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: cover;

    @media (min-width: 768px) {
      min-height: 40rem;
    }

    @media (min-width: 1025px) {
      min-height: 45rem;
    }
  }

  .footer-hero-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000000;
    opacity: 0.39;
    z-index: 9999;
  }

  .footer-content-wrapper {
    ${medWrapper};
    align-items: center;
    position: relative;
    z-index: 99999;
  }

  .footer-column {
    width: 100%;
    margin-bottom: 5rem;
    text-align: center;

    @media (min-width: 768px) {
      width: calc(50%);
      margin-bottom: 1rem;
    }

    h3 {
      color: ${colors.white};

      span {
        display: block;
        text-transform: uppercase;
        font-weight: normal;

        &:first-of-type {
          ${fontSizer(1.8, 2.4, 76.8, 110, 2.2)};
          font-family: ${fonts.fontPrimary};
          letter-spacing: 0.6px;
          line-height: 2.5;
        }

        &:last-of-type {
          ${fontSizer(2.6, 4, 76.8, 110, 3)};
          font-family: ${fonts.fontSecondary};
          letter-spacing: 1px;
          line-height: 1.5;
        }
      }
    }

    a {
      ${buttonOne};
    }
  }

  .footer-social-wrapper {
    width: 100%;

    a {
      color: ${colors.colorAccent};
    }
  }

  .footer-bottom-meta {
    padding: 1rem 2rem;
    background: ${colors.colorSecondary};
    text-align: center;
    p {
      ${fontSizer(1.4, 1.6, 76.8, 110, 1.4)};
      margin: 0;
      color: ${colors.white};

      a {
        ${fontSizer(1.4, 1.6, 76.8, 110, 1.4)};
        color: ${colors.white};
      }
    }

    .footer-copy {
      &__policies {
        margin-top: 2.5rem;
        margin-bottom: 5rem;

        a:hover {
          color: ${colors.colorTertiary};
        }

        a {
          display: block;
          margin-bottom: 1.5rem;

          @media (min-width: 768px) {
            display: inline-block;
            margin-bottom: 0;
          }
        }

        span {
          display: none;
          @media (min-width: 768px) {
            display: inline-block;
          }
        }
      }
    }
  }
`

const Footer = (props) => {
  const data = useStaticQuery(getData)
  const footerData = data.wordpressAcfOptions.options
  const swbCredit = footerData.dow_switchback_credit
  const imgFluid =
    footerData.dow_footer_background_image.localFile.childImageSharp.fluid

  const colOneTop = footerData.dow_column_one_title_top
  const colOneBot = footerData.dow_column_one_title_bot
  const colOneBtnText = footerData.dow_column_one_btn_text
  const colOneBtnUrl = footerData.dow_column_one_btn_url

  const colTwoTop = footerData.dow_column_two_title_top
  const colTwoBot = footerData.dow_column_two_title_bot
  const colTwoBtnText = footerData.dow_column_two_btn_text
  const colTwoBtnUrl = footerData.dow_column_two_btn_url

  return (
    <FooterStyled>
      <div className="footer-top-hero">
        <BgImg className="footer-hero-image" Tag="div" fluid={imgFluid}>
          <div className="footer-content-wrapper">
            <div className="footer-column footer-column-one">
              <h3>
                <span>{colOneTop}</span>
                <span>{colOneBot}</span>
              </h3>
              <Link to="/adventures">{colOneBtnText}</Link>
            </div>
            {/* <div className="footer-column footer-column-two">
              <h3>
                <span>{colTwoTop}</span>
                <span>{colTwoBot}</span>
              </h3>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={colTwoBtnUrl}
                onClick={() => {
                  if (typeof window !== "undefined") {
                    if (window.fbq != null) {
                      window.fbq("track", "Lead")
                    }
                  }
                }}
              >
                {colTwoBtnText}
              </a>
            </div> */}
            <div className="footer-social-wrapper">
              <SocialFollow />
            </div>
          </div>
          <div className="footer-hero-overlay" />
        </BgImg>
      </div>

      <div className="footer-bottom-meta">
        <div className="footer-copy">
          <div className="footer-copy__policies">
            <p>
              <Link to="/privacy-policy">Privacy Policy</Link>{" "}
              <span>&#124;</span> <Link to="/disclaimer">Disclaimer</Link>{" "}
              <span> &#124; </span>{" "}
              <Link to="/shop-policies">Shipping Policy</Link>{" "}
              <span> &#124; </span>{" "}
              <Link to="/shop-policies">Refund Policy</Link>{" "}
            </p>
          </div>
          <p>
            © {new Date().getFullYear()}, Drift Out West Fly Fishing.
            {` `}
          </p>
        </div>
        {swbCredit === "yes" && (
          <div>
            <p>
              Made in Airdrie. Designed and developed by Switchback Creative
              Inc. Built with ♡ and{" "}
              <a href="https://www.gatsbyjs.org">Gatsby</a>.
            </p>
          </div>
        )}
      </div>
    </FooterStyled>
  )
}

export default Footer
