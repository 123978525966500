const theme = {
  colorPrim: "#005d63",
  colorSec: "#008084",
  colorTert: "#DA3546",
  colorAcc: "#3FC1BF",
  colorSha: "#c8bbac",
  colorShaL: "#D2CABC",
  white: "#fff",
  grey: "#797e83",
  paraGrey: "#696969;",
  greyLight: "#eee",
  black: "#363636",
  strongred: "#f00",
  maxWidth: "1000px",
  baseLineHeight: "1.5",
  bpTablet: "768px",
  bpDesksm: "1025px",
  bpDeskmd: "1200px",
  bpDesklg: "1600px",
  bpMax: "1900px",
  fontPrim: "Work Sans",
  fontSec: "Josefin Sans",
  fontAwesome: "FontAwesome",
}

export default theme
