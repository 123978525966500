import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { colors } from "../../../Utilities"

const MainNavSubMenuItemStyled = styled.li`
  width: 100%;
  color: ${(props) => props.theme.black};
  font-family: ${(props) => props.theme.fontSec};
  font-weight: 500;
  font-size: 1.4rem;
  text-align: left;

  a.sub-menu-link {
    display: block;
    width: 100%;
    color: ${(props) => props.theme.black};
    padding: 0.5rem 1rem;
    background: transparent;

    &:hover {
      background: ${colors.colorTertiary};
      color: ${colors.white};
    }

    &[aria-current="page"] {
      background: ${colors.colorPrimary};
      color: ${colors.white};

      &:hover {
        background: ${colors.colorPrimary};
      }
    }
  }
`

const MainNavSubMenuItem = ({
  currentPageSlug,
  objectSlug,
  title,
  location,
}) => {
  const isMedia = objectSlug === "media"
  return (
    <MainNavSubMenuItemStyled>
      {isMedia ? (
        <Link className="sub-menu-link" to={`/${objectSlug}`}>
          {title}
        </Link>
      ) : (
        <Link className="sub-menu-link" to={`${currentPageSlug}/${objectSlug}`}>
          {title}
        </Link>
      )}
    </MainNavSubMenuItemStyled>
  )
}

export default MainNavSubMenuItem
