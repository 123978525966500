import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"

import { colors, fonts } from "../../../Utilities"

const InformationLinksStyled = styled.div`
  display: block;
  margin-top: 2.5rem;

  .info-link-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    margin: auto;
  }

  a {
    width: 100%;
    margin: 2rem 0;
    padding: 0 2rem;
    color: ${colors.colorAccent};
    font-family: ${fonts.fontPrimary};
    text-transform: uppercase;
    font-size: 2rem;
    text-align: center;

    &:hover {
      color: ${colors.colorShadowLight};
    }
  }
`

const getData = graphql`
  query informationLinksMobile {
    wordpressAcfOptions {
      options {
        dow_sportfishing_regulations
        dow_fishing_license
        dow_river_conditions
      }
    }
  }
`

const InformationLinksMobile = props => {
  const data = useStaticQuery(getData)
  const regUrl = data.wordpressAcfOptions.options.dow_sportfishing_regulations
  const liceUrl = data.wordpressAcfOptions.options.dow_fishing_license
  const condUrl = data.wordpressAcfOptions.options.dow_river_conditions

  return (
    <InformationLinksStyled>
      <div className="info-link-wrapper">
        <a target="_blank" rel="noopener noreferrer" href={regUrl}>
          Sportfishing Regulations
        </a>
        <a target="_blank" rel="noopener noreferrer" href={liceUrl}>
          Fishing License
        </a>
        <a target="_blank" rel="noopener noreferrer" href={condUrl}>
          River Conditions
        </a>
      </div>
    </InformationLinksStyled>
  )
}

export default InformationLinksMobile
