import React, { useContext } from "react"

import { UiContext } from "../../context/UiContext"
import ModalAlert from "./ModalAlert"
import ModalLoading from "./ModalLoading"

const UiModals = () => {
  const { state: uiState, dispatch } = useContext(UiContext)
  return (
    <>
      <ModalAlert
        alertactive={uiState.alertActive}
        message={uiState.message}
        error={uiState.error}
        uiDispatch={dispatch}
      />
      <ModalLoading loadingactive={uiState.loadingActive} />
    </>
  )
}

export default UiModals
